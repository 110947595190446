var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',{staticClass:"auth-pages bg-default-login",staticStyle:{"background":"url('/bg_footer.svg') no-repeat bottom fixed !important","background-size":"100% !important"}},[_c('v-container',{staticClass:"pb-0",staticStyle:{"margin-top":"150px","max-width":"80%"}},[_c('v-row',{staticClass:"d-flex justify-center mt-n16"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"6","xl":"5"}},[_c('v-form',[_c('v-card',{staticClass:"card-login",attrs:{"color":"rgb(255, 255, 255, 1)"}},[_c('v-card-title',{staticClass:"justify-center"},[_c('v-img',{staticClass:"logo-image-background-size",attrs:{"src":require("@/assets/img/logos/logo_color.svg"),"max-width":"300"}})],1),_c('v-card-text',{staticClass:"pa-1 font-size-root"},[_c('p',{staticClass:"text-center txt-acceso-top-2"},[_vm._v(" "+_vm._s(_vm.$t('Rellene el formulario para restablecer su contraseña'))+" ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre de usuario'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('Usuario'),"filled":""},model:{value:(_vm.passwordReset.username),callback:function ($$v) {_vm.$set(_vm.passwordReset, "username", $$v)},expression:"passwordReset.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Email","filled":""},model:{value:(_vm.passwordReset.email),callback:function ($$v) {_vm.$set(_vm.passwordReset, "email", $$v)},expression:"passwordReset.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Contraseña'),"rules":"required|confirmed:confirmation","vid":"firstConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('Contraseña'),"hint":_vm.$t('Contraseña (Mayúsculas, minúsculas, números y/o caracteres especiales y al menos 8 caracteres de longitud)'),"filled":"","type":_vm.showFirstPassword ?'text': 'password',"append-icon":_vm.showFirstPassword ?'fas fa-eye':'fas fa-eye-slash'},on:{"click:append":function($event){_vm.showFirstPassword = !_vm.showFirstPassword}},model:{value:(_vm.passwordReset.password),callback:function ($$v) {_vm.$set(_vm.passwordReset, "password", $$v)},expression:"passwordReset.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Confirmar Contraseña'),"rules":"required|confirmed:firstConfirmation","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('Confirmar Contraseña'),"hint":_vm.$t('Contraseña (Mayúsculas, minúsculas, números y/o caracteres especiales y al menos 8 caracteres de longitud)'),"filled":"","type":_vm.showSecondPassword ?'text': 'password',"append-icon":_vm.showSecondPassword ?'fas fa-eye':'fas fa-eye-slash'},on:{"click:append":function($event){_vm.showSecondPassword = !_vm.showSecondPassword}},model:{value:(_vm.passwordReset.password_confirmation),callback:function ($$v) {_vm.$set(_vm.passwordReset, "password_confirmation", $$v)},expression:"passwordReset.password_confirmation"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-0 pb-0"},[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"text-uppercase text-white btn-primary py-3 px-6 rounded-sm my-6 btn-login mt-0",attrs:{"disabled":invalid,"elevation":"0","ripple":false,"height":"43","color":"#0094de","loading":_vm.loading},on:{"click":_vm.resetPassword}},[_vm._v(" "+_vm._s(_vm.$t('Restablecer'))+" ")])],1)])],1)]}}])})],1)],1)],1)],1)],1),_c('alert')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }