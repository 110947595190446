import { PasswordReset } from '@/classes/PasswordReset.js'
import UserService from '@/services/user.service.js'
import Alert from '@/components/Alert/Alert.vue'

export default {
  name: 'PasswordReset',
  components: { Alert },
  data: function () {
    return {
      showFirstPassword: false,
      showSecondPassword: false,
      passwordReset: new PasswordReset(),
      loading: false,
    }
  },
  methods: {
    resetPassword () {
      this.loading = true
      UserService.resetPassword(this.passwordReset).then(
        () => {
          this.$router.push('/login')
        },
        (error) => {
          UserService.errorResponse(
            error.response.status,
            this.$t('Error al intentar cambiar la contraseña'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
  },
  mounted () {
    this.passwordReset.token = this.$route.params.token
  },
}
